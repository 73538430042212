import React, { useEffect } from "react"
import { graphql, Link } from "gatsby";
import Breadcrumb from "../components/Global/Breadcrumb"

import Layout from '../components/layout'
import "./../components/DynamicPage.css"




function GeneralTemplate(props) {
    let data = props.data;

    console.log(data)
    useEffect(() => {
        var elements = document.getElementsByClassName("faq-q");

        var myFunction = function (dt, am) {
            var attribute = this.siblings;
            console.log(dt.target.parentElement.nextElementSibling)
            if (dt.target.parentElement.nextElementSibling) {
                console.log(dt.target.parentElement.nextElementSibling.style.visibility)

                if (!dt.target.parentElement.nextElementSibling.style.visibility || dt.target.parentElement.nextElementSibling.style.visibility === "hidden") {
                    dt.target.parentElement.nextElementSibling.style.visibility = "visible";
                    dt.target.parentElement.nextElementSibling.style.height = "auto";
                } else {
                    dt.target.parentElement.nextElementSibling.style.visibility = "hidden";
                    dt.target.parentElement.nextElementSibling.style.height = "0";
                }

            }

        };

        for (var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('click', myFunction, false);
        }


        // if ( document && document.querySelector('.overlap-embed') ) {
        //     var old_element = document.querySelector('.overlap-embed');

        //     // get the parent
        //     var parent = document.querySelector('.embed-spec');

        //     // var new_element = document.querySelector('.embed-spec');

        //     // replace child of the parent
        //     parent.appendChild(old_element);
        // }

    }, [])

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>
                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <div className="container-cs px-4 m-auto py-16 dynamic-content" dangerouslySetInnerHTML={{ __html: data.allWpPage.nodes[0].content }}></div>
                </div>
            </Layout>
        </div>
    )
}

export default GeneralTemplate;

export const pageQuery = graphql`query GET_DYNAMIC_PAGE($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      title
      content
      breadcrumbAcf {
        breadcrumbName
        
      }
    }
    }
    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{

      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`
